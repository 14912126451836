
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { ElNotification } from 'element-plus'

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [] as any,
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: "",
        form : {} as any,
        loaderEnabledTest : false,
        typeEddEdit : "",
        ssOrga: [] as any,


    });

    const tableHeader = ref([
      { name: "Prénom Nom", key: "con_prenom", sortable: false},
      { name: "Utilisateur", key: "con_mail", sortable: false,},
      { name: "Role", key: "con_role_web", sortable: false,},
      { name: "Statut", key: "con_seq", sortable: false},  
      { name: "Sous Organisation", key: "listSsOrga", sortable: false,},
    ]);

      onMounted(async () => {  
        setCurrentPageBreadcrumbs("Paramétrage des utlisateurs", []);

        loadDatas();

      });

      const loadDatas = async () => {
        let nUsers = await getAxios("/getUsers");
        
        state.myList = nUsers.results;
        state.initialMyList = state.myList;
        
        const ssOrga = await getAxios("/getSousOrgaMeraki");       
        state.ssOrga = ssOrga.results;

        state.myList.forEach(element => {
          element.listSsOrga = element.listSsOrga ? element.listSsOrga.split(',') : [];
        });

        state.loaderEnabled = false;
      }

      const addEdit_user = (data) => {
        console.log(data);
        if(data.con_seq == -1){
          state.typeEddEdit = "Ajout d'un utilisateur";

          state.form.con_seq = -1;
          state.form.con_prenom = "";
          state.form.con_nom = "";
          state.form.con_mail = "";
          state.form.con_meraki_key = "";
          state.form.con_role_web = "Utilisateur";
          state.form.new_password = "";
          state.form.new_repassword = "";
          state.form.con_x_acces_web = true;
          state.form.lstssOrga = [];
          state.form.con_x_restr_ssorga = 0;

        } else {
          state.typeEddEdit = "Modification d'un utilisateur";

          state.form.con_seq = data.con_seq;
          state.form.con_prenom = data.con_prenom;
          state.form.con_nom = data.con_nom;
          state.form.con_mail = data.con_mail;
          state.form.con_meraki_key = data.con_meraki_key;
          state.form.con_role_web = data.con_role_web == "Administrateur" ? "Administrateur" : "Utilisateur";
          state.form.new_password = "";
          state.form.new_repassword = "";
          
          state.form.con_x_acces_web = false;
          if(data.con_x_acces_web == '1') state.form.con_x_acces_web = true;

          state.form.con_x_restr_ssorga = false;
          if(data.con_x_restr_ssorga == '1') state.form.con_x_restr_ssorga = true;

          state.form.lstssOrga = data.listSsOrga;
          
        }
        state.drawer = true;
      };

      const test_api = async () => {
        if(!state.form.con_meraki_key || state.form.con_meraki_key=="") {
          ElNotification({
                title: 'Erreur',
                message: 'Veuillez saisir une clé',
                type: 'error',
              })
          return false;
        }
        mAxiosApi
          .get("testAPIMeraki/" + state.form.con_meraki_key)
          .then(({ data }) => {
            if(data.results.length > 0) {
              console.log(data);
              ElNotification({
                title: 'Succès',
                message: 'Votre clé semble valide',
                type: 'success',
              })
            }else {
              ElNotification({
                title: 'Erreur',
                message: 'Votre clé ne semble pas valide',
                type: 'error',
              }) 
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      const applyChange = async () => {

        if (state.form.con_seq == -1 && !state.form.new_password) {
          ElNotification({ title: 'Error', message: "Lors de la création d'un compte, le mot de passe doit être stipulé", type: 'error',})
          return false;
        }

        if (state.form.new_password && state.form.new_password != state.form.new_repassword) {
          ElNotification({ title: 'Error', message: "Les mots de passe saisis sont différents", type: 'error',})
          return false;
        }

        if (!state.form.con_mail || !state.form.con_prenom || !state.form.con_nom){
          console.log(state.form);
          ElNotification({ title: 'Error', message: 'Un des champs obligatoire n\'est pas saisi', type: 'error',})
          return false;
        } 

        const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!regexMail.test(state.form.con_mail)){
          ElNotification({ title: 'Error', message: 'Le mail de connexion doit être un mail valide', type: 'error',})
          return false;
        }

        const form = JSON.stringify(state.form);
        await mAxiosApi.post("/addEditUser", form, { headers: {   'Content-Type': 'application/json'  }  });
        ElNotification({ title: 'Succés', message: 'Changements effectués', type: 'success',})
        
        await loadDatas();
        state.loadingDatatable = state.loadingDatatable + 1;
        state.drawer = false;

      }
  
      return {
        state,
        store,
        tableHeader,
        addEdit_user,
        test_api,
        applyChange
      };
    },
  });
  
  export const getAxios = async (rr) => { const re = await mAxiosApi.get(rr); return re.data; };

  